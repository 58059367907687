<template>
  <b-container class="px-4">
    <b-row class="page-header py-4" no-gutters>
      <b-col class="text-center text-sm-left mb-4 mb-sm-0" col sm="12">
        <!--        <span class="text-uppercase page-subtitle">About us</span>-->
        <h3 class="page-title">ABOUT US</h3>
      </b-col>
    </b-row>
    <b-card>
      <b-row>
        <b-col>
          <video autoplay="" muted="" loop="" preload="metadata" id="aboutPromo">
            <source src="https://tvsengineering.com/wp-content/themes/TVS/img/promo_low.mp4" type="video/mp4">
          </video>
        </b-col>
        <b-col>
          <h5>
           Since 2005 TVS has been the pioneer in DSG software
          </h5>

          <ul style="list-style: none">
            <li><i class="fa fa-check text-success"></i> 10+ employees</li>
            <li><i class="fa fa-check text-success"></i> 100+ dealer and subdealers worldwide</li>
            <li><i class="fa fa-check text-success"></i> 5.000+ programmations per year.</li>
          </ul>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="mt-2">
      <b-row>
        <b-col>
          <h5>COMPANY PROFILE</h5>
          <p>
            Since 2005 TVS has been the pioneer in DSG software
            <br>
            Over the years we performed thousands of diagnostics, repairs, and hardware upgrades worldwide  and with that experience, we can make the best DSG software.
            <br>
           During most appointments, you can wait for your DSG optimizations. Ready in minutes. Test yourself and pay when you are satisfied.
          </p>
        </b-col>
        <b-col>
          <img alt="Company profile"
               style="width: 100%"
               data-src="https://b1936034.smushcdn.com/1936034/wp-content/uploads/2020/04/IMG_4117-scaled.jpeg?lossy=1&strip=1&webp=1"
               class=""
               src="https://b1936034.smushcdn.com/1936034/wp-content/uploads/2020/04/IMG_4117-scaled.jpeg?lossy=1&strip=1&webp=1">
        </b-col>
      </b-row>
    </b-card>

    <b-card class="mt-2">
      <b-row>
        <b-col>
          <img alt="Ready while you wait"
               style="width: 100%"
               data-src="https://b1936034.smushcdn.com/1936034/wp-content/uploads/2020/01/showroom_web.jpg?lossy=1&strip=1&webp=1"
               class=" ls-is-cached lazyloaded"
               src="https://b1936034.smushcdn.com/1936034/wp-content/uploads/2020/01/showroom_web.jpg?lossy=1&strip=1&webp=1">
        </b-col>
        <b-col>
          <h5>READY WHILE YOU WAIT</h5>
          <p>
            During most appointments, you can wait at TVS headquarter while we carry out the
            requested job on your vehicle, like an extensive vehicle diagnosis, oil change, minor repairs or DSG
            optimizations.
            In our comfortable waiting room you can use free WiFi, food and drinks. Of
            course it is also possible to have a look during the work performed by TVS technicians.
          </p>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="mt-2">
      <b-row>
        <b-col>
          <h5>Company information</h5>
          <p> <span class="font-weight-bold">Company: </span>TVS GROUP INTERNATIONAL</p>
          <p><span class="font-weight-bold">Contact: </span> Ronald Logmans +57 3042909906</p>
          <p>
            <span class="font-weight-bold">Address: </span>
           AV. SAMUEL LEWIS 54E
            <br>
           32401, Panama-city. Panama
          </p>
          <p> <span class="font-weight-bold">Email: </span> ronald@tvsgroup.nl</p>
        </b-col>
        <b-col>
          <img alt="Rental cars"
               style="width: 100%;"
               data-src="https://b1936034.smushcdn.com/1936034/wp-content/uploads/2020/04/RentalCars_Promo-scaled.jpeg?lossy=1&strip=1&webp=1"
               class=" lazyloaded"
               src="https://b1936034.smushcdn.com/1936034/wp-content/uploads/2020/04/RentalCars_Promo-scaled.jpeg?lossy=1&strip=1&webp=1">
        </b-col>
      </b-row>
    </b-card>
    <b-row class="mt-2">
      <b-col class="text-center">
        <a style="font-size: 1.2rem" href="https://tvs-admin.s3.us-west-2.amazonaws.com/TVS+terms+and+condition.pdf" target="_blank">Terms and conditions</a>
      </b-col>
      <b-col  class="text-center">
        <a style="font-size: 1.2rem" href="https://tvs-admin.s3.us-west-2.amazonaws.com/TVS+license+agreements.pdf" target="_blank">License agreement</a>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
import {INVOICE_INFORMATION_DEFUALT} from "@/constants";


export default {
  name: "index",
  data: () => ({
    INFORMATION: INVOICE_INFORMATION_DEFUALT
  })
}
</script>

<style scoped lang="scss">
ul.aboutBullets .la, .flexThis .la-check {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-color: #33ad53;
  border-radius: 50%;
  color: white;
  text-align: center;
  line-height: 20px;
  font-size: 9px;
  margin-right: 5px;
}
</style>
